import { getMessageFromSession, getLang } from './utils';

document.addEventListener('click', event => {
  try {
    if (event.target.dataset.back) {
      const message = getMessageFromSession();
      const lang = getLang();

      event.preventDefault();
      location.assign(
        Object
          .entries(message)
          .reduce((t, v) => t.replace(`{${v[0]}}`, v[1]), `${event.target.dataset.back}.${lang}.html`)
      );
    }
  } catch (error) {}
});
