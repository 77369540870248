const SIZE = 12;

export const $ = document.querySelector.bind(document);

export const $$ = document.querySelectorAll.bind(document);

export function getLang() {
  const available = ['es', 'en'];
  try {
    return navigator.languages
      .map(lang => /^[a-z]+/.exec(lang.toLowerCase())[0])
      .filter(lang => available.includes(lang))
      .shift() || available[0];
  } catch(error) {
    return available[0];
  }
}

export function isValidOption(value) {
  return ['oso', 'piruleta', 'helado'].includes(value);
}

export function isValidMessage(message) {
  const { from, to, text, option } = message;
  return isValidOption(option) && [from, to, text].every(v => v);
}

export function clean(text = '') {
  return text
    .trim()
    .replace(/ +/mg, ' ')
    .replace(/\n+/mg, '\n');
}

export function wrap(text = '') {
  const lines = text
  .split('\n')
  .flatMap(line => {
    const words = line.trim().split(' ').flatMap(word => {
      const size = SIZE - 1;
      const chunks = Array.from({ length: Math.ceil(word.length / size) }, (_, i) => {
        return word.slice(i * size, i * size + size);
      }).map(w => `${w}-`);

      if (chunks.length > 0) {
        chunks[chunks.length - 1] = chunks[chunks.length - 1].slice(0, -1);
      }
  
      return chunks;
    });
    const wrap = [''];
    while (words.length) {
      while (words.length && wrap[wrap.length - 1].length <= SIZE) {
        if (words[0].length + wrap[wrap.length - 1].length > SIZE) {
          break;
        }
        wrap[wrap.length - 1] = `${wrap[wrap.length - 1]} ${words.shift()}`;
      }
      wrap.push('');
    }
    return wrap.filter(w => w);
  })
  .slice(0, 4)
  
  if (lines.length > 0) {
    lines[lines.length - 1] = lines[lines.length - 1].replace(/\-$/, '');
  }

  return lines;
}

export function getKey() {
  return window.IS_VIEW ? 'VIEW_MESSAGE' : 'WRITE_MESSAGE';
}

export function setInSession(key, data) {
  try {
    sessionStorage.setItem(key, JSON.stringify(data));
  } catch (error) {}
}

export function getFromSession(key, def) {
  try {
    return JSON.parse(sessionStorage.getItem(key));
  } catch (error) {
    return def;
  }
}

export function getMessageFromSession() {
  return getFromSession(getKey());
}

export function setMessageInSession(message) {
  return setInSession(getKey(), message);
}
